<template>
  <ul class="text-primary-dark flex gap-2">
    <li v-for="item in list" :key="item.url">
      <a
        class="flex aspect-square items-center justify-center rounded p-1 transition-colors hover:bg-white"
        :class="item.class"
        :href="item.url"
        target="_blank"
      >
        <app-icon :icon="item.icon" />
        <span class="sr-only">{{ item.label }}</span>
      </a>
    </li>
  </ul>
</template>

<script lang="ts" setup>
const {
  public: {
    socialMediasUrls: { linkedin, twitter, instagram, tiktok },
  },
} = useRuntimeConfig();
const list: { label: string; url: string; icon: string; class: string }[] = [
  {
    label: "Linkedin",
    class: "hover:text-[#0966C2]",
    url: linkedin,
    icon: "mdi:linkedin",
  },
  {
    label: "Twitter",
    class: "hover:text-[#1C9BF0]",
    url: twitter,
    icon: "mdi:twitter",
  },
  {
    label: "Instagram",
    class: "hover:text-[#D302C5]",
    url: instagram,
    icon: "mdi:instagram",
  },
  {
    label: "TikTok",
    class: "hover:text-[#000]",
    url: tiktok,
    icon: "ic:baseline-tiktok",
  },
];
</script>
